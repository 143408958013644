body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.markdown-content {
  padding: 20px;
}
.markdown-content pre {
  background-color: #42485b;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  margin-right: 40px;
}
