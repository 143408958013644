/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

.App-header {
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 20px;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #95b3ef;
}

.App-body {
  /*background-color: #95b3ef;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.collection {
  margin-top: 40px;
  /*min-width: 60%;*/
  /*width: 60%;*/
  /*margin-left: 10%;*/
  /*margin-right: 10%;*/
}

.tabs {
  /*align-self: stretch;*/
}

.answerHistory .amplify-text{
  color: cornflowerblue;
}